<template>
    <b-container v-if="discount">
        <b-row>
            <b-col>
                <h3 class="mt-3">{{ discount.id ? '修改折扣' : '建立折扣' }}</h3>
            </b-col>
            <b-col class="mt-3 float-right" md="12" lg="4">
                <b-button v-if="discount.id" variant="danger" block @click="onDelete">刪除</b-button>
            </b-col>
        </b-row>
        <b-form class="mt-3" @submit.stop.prevent="onSubmit">
            <h5>內容</h5>
            <b-form-group label="優惠碼">
                <b-form-input v-model="discount.code"></b-form-input>
            </b-form-group>
            <b-form-group label="名稱">
                <b-form-input v-model="discount.name" required></b-form-input>
            </b-form-group>
            <b-form-group label="類型">
                <b-form-select v-model="discount.type" :options="typeOptions" required></b-form-select>
            </b-form-group>
            <b-form-group label="數值">
                <b-input-group :prepend="`${discount.type.includes('AMOUNT') ? '$' : ''}`" :append="`${discount.type.includes('PERCENTAGE') ? '%' : ''}`">
                    <b-form-input v-model.number="discount.amount" type="number" step="0.1" required></b-form-input>
                </b-input-group>
            </b-form-group>

            <h5>條件</h5>
            <b-form-group v-if="discount.schema && discount.schema >= 2 && !discount.type.includes('PRODUCT')">
                <b-form-checkbox v-model="discount.oncePerOrder">每單訂單只可用一次</b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="discount.schema && discount.schema >= 2 && !discount.type.includes('PRODUCT')">
                <b-form-checkbox v-model="discount.onlyInOrder">每張訂單只可以有此折扣</b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="discount.schema && discount.schema >= 3 &&discount.type.includes('PRODUCT')" label="可選商品">
                <n-multiselect label="name" :options="products" :reset-after="true" @select="addProductId"></n-multiselect>
            </b-form-group>
            <b-form-group v-if="discountPresentAtProducts.length > 0">
                <b-list-group>
                    <b-list-group-item v-for="(product, index) in discountPresentAtProducts" :key="index">
                        <b-row>
                            <b-col class="pt-2">{{ product.name }}</b-col>
                            <b-col cols="4">
                                <b-button variant="danger" block @click="removeProductId(index)">刪除</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
            </b-form-group>

            <h5>分店</h5>
            <b-form-group label="可用分店">
                <n-multiselect label="name" :options="shops" :reset-after="true" @select="addShopId"></n-multiselect>
            </b-form-group>
            <b-form-group v-if="discountPresentAtShops.length > 0">
                <b-list-group>
                    <b-list-group-item v-for="(shop, index) in discountPresentAtShops" :key="index">
                        <b-row>
                            <b-col class="pt-2">{{ shop.name }}</b-col>
                            <b-col cols="4">
                                <b-button variant="danger" block @click="removeShopId(index)">刪除</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group>
                        <b-button block @click="$router.go(-1)">返回</b-button>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-button type="submit" variant="primary" block>儲存</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { Discount } from '@/models'

export default {
    data() {
        return {
            action: this.$route.params.action,
            discount: null,
            typeOptions: [
                { value: 'AMOUNT', text: '全單金額' },
                { value: 'PERCENTAGE', text: '全單百分比' },
                { value: 'PRODUCT_AMOUNT', text: '商品金額' },
                { value: 'PRODUCT_PERCENTAGE', text: '商品百分比' },
            ],
        }
    },

    async created() {
        this.discount = this.action == 'create' ? Discount() : await this.$store.dispatch('getDocument', { col: 'discounts', id: this.$route.params.id })
        if (!this.discount) return this.$router.push('/discounts')
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'shops', merchantId: localStorage.merchantId })
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'products', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            shops: state => state.shops,
            products: state => state.products,
        }),

        discountPresentAtShops() {
            let ret = []
            this.discount.presentAtShopIds.forEach(id => {
                ret.push(this.shops.find(shop => shop.id == id))
            })
            return ret
        },

        discountPresentAtProducts() {
            let ret = []
            if (!this.discount.schema && !this.discount.schema <= 2) return ret
            this.discount.presentAtProductIds.forEach(id => {
                ret.push(this.products.find(product => product.id == id))
            })
            return ret
        },
    },

    methods: {
        async onDelete() {
            const res = await this.$store.dispatch('deleteDocument', { col: 'discounts', id: this.discount.id })
            this.$root.$bvToast.toast(`刪除${res ? '成功' : '失敗'}`, { title: res ? '成功' : '失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/discounts')
        },

        addShopId(obj) {
            if (!this.discount.presentAtShopIds.find(id => id == obj.id)) return this.discount.presentAtShopIds.push(obj.id)
        },

        async removeShopId(index) {
            try{
                this.discount.presentAtShopIds.splice(index, 1)
                if (this.action == 'create') return 
                await this.$store.dispatch('updateDocument', { col: 'discounts', doc: this.discount })
                this.$root.$bvToast.toast('成功刪除',{title:'刪除成功',variant:'success'})
            } catch (error) {
                this.$root.$bvToast.toast(error,{title:'刪除錯誤',variant:'danger'})
            }
        },

        addProductId(obj) {
            if (!this.discount.presentAtProductIds.find(id => id == obj.id)) return this.discount.presentAtProductIds.push(obj.id)
        },

        async removeProductId(index) {
            try{
                this.discount.presentAtProductIds.splice(index, 1)
                if (this.action == 'create') return 
                await this.$store.dispatch('updateDocument', { col: 'discounts', doc: this.discount })
                this.$root.$bvToast.toast('成功刪除',{title:'刪除成功',variant:'success'})
            } catch (error) {
                this.$root.$bvToast.toast(error,{title:'刪除錯誤',variant:'danger'})
            }
        },

        async onSubmit() {
            this.discount.merchantId = localStorage.merchantId

            const res = await this.$store.dispatch(this.action == 'create' ? 'createDocument' : 'updateDocument', { col: 'discounts', doc: this.discount })
            this.$root.$bvToast.toast(`${this.action == 'create' ? '新增' : '更新'}${res ? '成功' : '失敗'}`, { title: res ? '成功' : '失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/discounts')
        },
    },
}
</script>
